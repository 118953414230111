import React from 'react'
import styled from '@emotion/styled'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import One from './1'
import Two from './2'
import Three from './3'
import Four from './4'
import Five from './5'
import Six from './6'

const CompanyBlockList = props => (
  <Root {...props}>
    <Row><One /></Row>
    <Row><Two /></Row>
    <Row><Three /></Row>
    <Row><Four /></Row>
    <Row><Five /></Row>
    <Row><Six /></Row>
  </Root>
)

const Root = styled.div``

const Row = styled.div`
  margin-top: 80px;
  @media ${mq.and(mobile)} {
    margin-top: 64px;
  }
  &:first-of-type {
    margin-top: 0;
  }
  @media ${mq.and(mobile)} {
    &:first-of-type {
      margin-top: 0;
    }
  }
`

export default CompanyBlockList
