import React, { Fragment } from 'react'
import Block from './Block'

const CompanyBlock = props => (
  <Block
    {...props}
    title={{
      sub: 'スタイル',
      main: 'ワガママであれ。'
    }}
    body={(
      <Fragment>好きなことを好きなようにやるのが最大の結果を生みます。ワガママな生き方のほうがストレスが少なく、幸福感があり精神的にも健康でいられることが多いのです。節度、責任、義務、協調性は必要ですが、自由な雰囲気でで好きなことを精一杯やってください。</Fragment>
    )}
  />
)

export default CompanyBlock
