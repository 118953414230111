import React, { Fragment } from 'react'
import Block from './Block'

const CompanyBlock = props => (
  <Block
    {...props}
    title={{
      sub: '快適な勤務環境',
      main: '服装は自由、オフィスは神奈川と千葉で快適通勤。'
    }}
    body={(
      <Fragment>自社内作業が中心なので、服装は自由です。あまり派手では困りますが、常識の範囲内でOKです。本社は、神奈川県の橋本駅から徒歩5分程度の国道16号線沿いにあります。民家のような建物ではありますが、2007年に本社オフィスとして建てた自社ビルです。千葉県の船橋市にもオフィスがあるので、より近い方に通勤していただくことになります。通勤は下り方面になるので比較的楽です。自転車、バイク、徒歩の人が多く、エコに貢献していると思っています。</Fragment>
    )}
  />
)

export default CompanyBlock
