import React, { Fragment } from 'react'
import Block from './Block'

const CompanyBlock = props => (
  <Block
    {...props}
    title={{
      sub: '教育制度',
      main: '3ヶ月のプログラミング研修'
    }}
    body={(
      <Fragment>ウィザードでは新卒者、未経験者の方向けにスクールでの３ヶ月間のプログラミング研修を実施しております。OJTで成長促すだけでなく、しっかりと基礎の技術を身に着けていただくとともに、適性を判断してその後の職務に生かしていただきます。</Fragment>
    )}
  />
)

export default CompanyBlock
