import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Block from './Block'

const CompanyBlock = props => (
  <StaticQuery
    query={query}
    render={data => (
      <Block
        {...props}
        images={[data.image1, data.image2]}
        title={{
          sub: '充実の福利厚生',
          main: '長野には自社保養所も'
        }}
        body={(
          <Fragment>福利厚生施設として長野県の蓼科に保養所をオープンしました。<a href="http://www.tateshina-tokyu.com/" target="_blank" rel="noopener noreferrer">東急リゾートタウン蓼科(</a>内にあるリゾートマンションの1室です。標高1300mの高原リゾートでテニス、トレッキング、ゴルフ、スキーなどを楽しむことができます。将来リモートオフィスのような形でリゾート地で働ける環境作りを進めています。</Fragment>
        )}
      />
    )}
  />
)

export const query = graphql`
  query RecruitCompanyBlock6Query {
    image1: file(relativePath: { eq: "company-6-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "company-6-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CompanyBlock
