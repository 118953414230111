import React, { Fragment } from 'react'
import Block from './Block'

const CompanyBlock = props => (
  <Block
    {...props}
    title={{
      sub: '大事にしていること',
      main: '何よりも家族'
    }}
    body={(
      <Fragment>エンジニア、そしてその家族をも大事にし続ける会社であり続けます。当社の理念の1つに『会社は社員とその家族のためにある。』というのがあります。我々は、「社員とその家族の幸せが提供できてこそ存在価値があり、会社を大きくすることや立派にすることが当社の存在理由ではない」と考えています。代表も含め、社員の90％以上が技術者であり、エンジニアの気持ちは十分理解しています。また、エンジニアが当社の財産であり、一番大切だと考えています。</Fragment>
    )}
  />
)

export default CompanyBlock
