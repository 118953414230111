import React, { Fragment } from 'react'
import Block from './Block'

const CompanyBlock = props => (
  <Block
    {...props}
    title={{
      sub: 'やりがい',
      main: '企画から保守まで一貫して行える楽しさ'
    }}
    body={(
      <Fragment>ウィザードは、受託開発会社としては珍しく、大型の案件もSIerを通さずにクライアントから直接いたただくケースがほとんどです。クライアント様とは長い付き合いなので、当社社長を含め私たちをよく知っています。そのため、開発がしやすい環境で、要件定義から、設計、実装、テストまで、全ての工程に関わることができます。「プロジェクトに一から携わりたい」「運用から得られる生きた知識を身に着けたい」そんな想いがある方はご活躍頂けると思います。また、あなたのセンスで、より良いシステムにするための提案も可能です。</Fragment>
    )}
  />
)

export default CompanyBlock
